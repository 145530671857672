.download-page_container {
    width: 100%;
    height: 100%;
    z-index: 1000;
    margin-top: 5vh;
}

.download-page_content {
    width: 100%;
    height: auto;
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.download-page_infos {
    width: 20%;
    font-size: 12px;
    color: #FFF;
    text-align: center;
}


.download-page_infos strong {
    display: inline-block;
}

.download-page_infos-texts {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}

.download-page_infos-texts span {
    margin-bottom: 16px;
}

.download-page_infos-texts strong {
    margin-bottom: 10px;
}

.download-page_infos-texts strong + strong {
    margin-bottom: 20px;
}

.app-presentation {
    color: #00FF79;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.app-presentation img {
    width: 19vw !important;
    height: 20vw !important;
    object-fit: cover;
}

.download-page_image-montage {
    width: 50vw;
    height: 80vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, auto);
    row-gap: 10px;
    column-gap: 10px;
    margin-bottom: 20px;
}

.download-page_image-montage img {
    border-radius: 10px;
}

.download-page_image-montage #lake {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.download-page_image-montage #mountain {
    /* grid-area: mountain; */
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.download-page_image-montage #surf {
    /* grid-area: surf; */
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 4;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.download-page_image-montage #sentence {
    /* grid-area: sentence; */
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 6;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.download-page_image-montage #cycle {
    /* grid-area: cycle; */
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.download-page_content img {
    width: 50vw;
    height: 50vw;
}

.download-page_content strong.text_description_spotlight {
    color: #00FF79;
    padding-bottom: 40px;
}

.modal_container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
}

.app_pass_modal {
    width: 70%;
    height: auto;
    text-align: center;
    background-color: #222222;
    border-radius: 20px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.app_pass_modal_title {
    color: #00FF79;
}

.app_pass_modal_desc {
    color: #FFF;
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 14px;
}

.app_pass_modal button {
    margin-top: 30px;
    border: none;
    background-color: #00FF79;
    width: 120px;
    border-radius: 15px;
    font-weight: 800;
    font-family: 'Montserrat';
}

@media (max-width: 600px) {
    .download-page_content {
        flex-direction: column;
    }

    .download-page_content .download-page_infos {
        width: 50%;
    }

    .download-page_content .download-page_image-montage {
        width: 75vw;
    }
}