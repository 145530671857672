.about-page_container {
    overflow-x:hidden;
    position: relative;
    width: auto;
}

.about-page_introduction {
    background-image: url("../../public/travel-buddies2.webp");
    width: 100vw;
    height: 95vh;
    margin-top: -100px;
    mix-blend-mode: screen;  
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    opacity: 0.5;
}

.about-page_introduction-title {
    position: absolute;
    top: 25vh;
    width: 100%;
    margin-left: -5%;
    text-align: center;
    z-index: 200 !important;
    opacity: 1 !important;
}

.about-page_introduction-title h1 {
    color: #FFFFFF;
    font-size: 6.5vw;
    font-weight: 800;
    margin-block-start: 0;
    margin-block-end: 0;
}

.strong-green-text.title {
    margin-left: 40% !important;
    font-size: 8vw !important;
}

.app-motivation-section {
    margin: 20px 0px 20px;
}

.app-motivation-subtitle {
    font-size: 18px;
    color: #FFFFFF;
    text-align: center;
}

.app-motivation-desc {
    font-size: 15px;
    color: #FFFFFF;
    text-align: center;
    white-space: pre-wrap;
}

.apps-possibilities {
    margin: 50px 0px 20vw;
    overflow-y: hidden;
}

.gym-buddies-photo {
    background-image: url("../../public/gym-buddies.webp");
    width: 55vw;
    height: 30vw;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-left: -20px;
    mix-blend-mode: screen;  
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    opacity: 0.5;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.gym-buddies-photo span {
    padding-bottom: 15px;
    color: #FFFFFF;
    font-size: 22px;
}

.skate-buddies-photo {
    background-image: url("../../public/skate-buddies.webp");
    width: 55vw;
    height: 30vw;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    position: absolute;
    right: 0px;
    margin-top: -15vw;
    mix-blend-mode: screen;  
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    opacity: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: pre-wrap;
}

.skate-buddies-photo span {
    font-size: 22px;
    text-align: center;
}

.app-existence-desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.app-existence-desc p {
    text-align: center;
    color: #FFFFFF;
    font-size: 20px;
    white-space: pre-wrap;
}

.app-existence-desc-box {
    width: 90%;
    height: auto;
    padding-top: 5px;
    padding-bottom: 20px;
    background: #222222;
    border-radius: 15px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.app-existence-desc-box strong {
    color: #FFFFFF;
    font-size: 2.9vw;
    margin-bottom: 10px;
}

.app-existence-carousel {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.app-existence-carousel-label-container {
    width: 80%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    z-index: 10;
}

.app-existence-carousel-label {
    color: #00FF79;
    font-size: 18px;
    padding-bottom: 20px;
}

.app-existence-carousel-images {
    width: 80%;
    height: 35vw;
}

.app-existence-carousel-images.cliff-jump-image {
    padding-top: 150px;
}

.app-existence-carousel-images img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
    opacity: 0.35;
}

.app-existence-carousel-images img.cliff-jump-image {
    object-position: center 22%;
}

.app-existence-carousel-images img.steps-image {
    object-position: center 70%;
}

.app-existence-desc-box button img {
    color: #00FF79;
}

.download_call_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px
}

.download_call_container span {
    margin-bottom: 16px
}