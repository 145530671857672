@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700;800&display=swap);
button {
  font-family: 'Montserrat';
  cursor: pointer;
}

body {
  font-family: 'Montserrat';
  margin: 0;
  padding: 0;
  background: #141414;
}

h1 {
  -webkit-margin-before: 0 !important;
          margin-block-start: 0 !important;
  -webkit-margin-after: 0 !important;
          margin-block-end: 0 !important;
}

.invisible-button {
  background-color: transparent;
  border-color: transparent;
}

.adventurous-logo {
  position: absolute;
  top: 0px;
  left: 0;
  max-width: 200px;
  max-height: 100px;
  width: 25%;
  height: auto;
  z-index: 200;
  border-bottom-right-radius: 20px;
  cursor: pointer;
}

.strong-green-text {
  color: #00FF79 !important;
}

.adventurous-button {
  width: auto;
  height: auto;
  font-size: 2vw;
  padding: 5px 10px;
  border-radius: 7px;
  border-color: transparent;
  background-color: #00FF79;
  color: #FFFFFF;
}

.navigation-header {
  position: absolute;
  right: 0px;
  padding-right: 20px;
  justify-content: flex-end;
  top: -16px;
  padding-bottom: 5px;
  background-color: rgba(0,0,0,0.5);
  border-bottom-left-radius: 20px;
  -webkit-padding-start: 15px;
          padding-inline-start: 15px;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 200;
}

.navigation-header li {
  font-size: 20px;
  margin-left: 1vw;
  cursor: pointer;
  border-left-width: 1;
  border-left-color: #000000;
}

.navigation-header li a {
  text-decoration: none;
  color: #000000;
}

.navigation-header.white li a {
  color: #FFFFFF;
}

.download-button {
  background-color: #00FF79;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 700;
  width: 160px;
  height: 40px;
  border-color: transparent;
  margin-bottom: 20px;
  margin-left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.no-style-button {
  border: none;
  background: none;
}

.download_call {
  margin-top: 5%;
  font-size: 1vw;
  color: #FFF;
}

@media (max-width: 600px) {
  .navigation-header {
    padding-right: 5px;
    -webkit-padding-start: 5px;
            padding-inline-start: 5px;
  }

  .navigation-header li {
    font-size: 12px !important;
    margin-left: 10px;
  }

  .title {
    font-size: 18px !important;
  }

  .subtitle {
    font-size: 12px !important;
  }

  .text {
    font-size: 10px !important;
  }

  .download-button {
    font-size: 15px !important;
    width: 100px !important;
    height: 30px !important;
  }
}
.index-page_container {
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.index-page_call {
    height: auto;
}

.index-page_call-first-text {
    width: 100%;
    margin-top: 10%;
    color: #FFFFFF;
    font-size: 7vw;
    text-align: center;
    z-index: 200;
}

.index-page_call-second-text {
    background-color: #00FF79;
    width: 52%;
    color: #000000;
    font-size: 7vw;
    text-align: end;
    padding-right: 10px;
    box-sizing: border-box;
    z-index: 0;
}

.index-page_call-second-text strong {
    z-index: 300;
}

.skateboarding-container {
    background: #FFFFFF;
}

.skateboarding-image {
    z-index: 100;
    position: absolute; 
    right: 0;
    top: 0;
    margin-top: -5vw;
    width: 48%;
    border-bottom-left-radius: 35vw;
    mix-blend-mode: screen;
}

.snowboarding-image {
    left: 0;
    width: 20%;
    -webkit-filter: gray;
            filter: gray;
    border-bottom-right-radius: 100px;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    mix-blend-mode: screen;
}

.climbing-image {
    width: 40%;
    height: auto;
    border-radius: 20px 0px 0px 20px;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    mix-blend-mode: screen;
}

.index-page_explanation {
    margin-top: 15%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
}

.index-page_explanation-text {
    padding: 0px 10vw;
    color: #FFFFFF
}

.index-page_explanation-text p {
    font-size: 2.4vw;
}

.index-page_explanation-title {
    text-align: center;
    font-size: 4vw;
}

.index-page_redirection {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 10%;
    font-size: 3vw;
    color: #FFFFFF;
}

.travelling-image {
    width: 95%;
    height: 50vw;
    object-fit: cover;
    margin-top: 20px;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    mix-blend-mode: screen;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.index-page_container button {
    margin-top: 20px;
    font-family: "Montserrat";
    cursor: pointer;
    color: #000000;
}
.about-page_container {
    overflow-x:hidden;
    position: relative;
    width: auto;
}

.about-page_introduction {
    background-image: url(/static/media/travel-buddies2.c8d302cd.webp);
    width: 100vw;
    height: 95vh;
    margin-top: -100px;
    mix-blend-mode: screen;  
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    opacity: 0.5;
}

.about-page_introduction-title {
    position: absolute;
    top: 25vh;
    width: 100%;
    margin-left: -5%;
    text-align: center;
    z-index: 200 !important;
    opacity: 1 !important;
}

.about-page_introduction-title h1 {
    color: #FFFFFF;
    font-size: 6.5vw;
    font-weight: 800;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
}

.strong-green-text.title {
    margin-left: 40% !important;
    font-size: 8vw !important;
}

.app-motivation-section {
    margin: 20px 0px 20px;
}

.app-motivation-subtitle {
    font-size: 18px;
    color: #FFFFFF;
    text-align: center;
}

.app-motivation-desc {
    font-size: 15px;
    color: #FFFFFF;
    text-align: center;
    white-space: pre-wrap;
}

.apps-possibilities {
    margin: 50px 0px 20vw;
    overflow-y: hidden;
}

.gym-buddies-photo {
    background-image: url(/static/media/gym-buddies.7d29db8d.webp);
    width: 55vw;
    height: 30vw;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-left: -20px;
    mix-blend-mode: screen;  
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    opacity: 0.5;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.gym-buddies-photo span {
    padding-bottom: 15px;
    color: #FFFFFF;
    font-size: 22px;
}

.skate-buddies-photo {
    background-image: url(/static/media/skate-buddies.50e0d020.webp);
    width: 55vw;
    height: 30vw;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    position: absolute;
    right: 0px;
    margin-top: -15vw;
    mix-blend-mode: screen;  
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    opacity: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: pre-wrap;
}

.skate-buddies-photo span {
    font-size: 22px;
    text-align: center;
}

.app-existence-desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.app-existence-desc p {
    text-align: center;
    color: #FFFFFF;
    font-size: 20px;
    white-space: pre-wrap;
}

.app-existence-desc-box {
    width: 90%;
    height: auto;
    padding-top: 5px;
    padding-bottom: 20px;
    background: #222222;
    border-radius: 15px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.app-existence-desc-box strong {
    color: #FFFFFF;
    font-size: 2.9vw;
    margin-bottom: 10px;
}

.app-existence-carousel {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.app-existence-carousel-label-container {
    width: 80%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    z-index: 10;
}

.app-existence-carousel-label {
    color: #00FF79;
    font-size: 18px;
    padding-bottom: 20px;
}

.app-existence-carousel-images {
    width: 80%;
    height: 35vw;
}

.app-existence-carousel-images.cliff-jump-image {
    padding-top: 150px;
}

.app-existence-carousel-images img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
    opacity: 0.35;
}

.app-existence-carousel-images img.cliff-jump-image {
    object-position: center 22%;
}

.app-existence-carousel-images img.steps-image {
    object-position: center 70%;
}

.app-existence-desc-box button img {
    color: #00FF79;
}

.download_call_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px
}

.download_call_container span {
    margin-bottom: 16px
}
.download-page_container {
    width: 100%;
    height: 100%;
    z-index: 1000;
    margin-top: 5vh;
}

.download-page_content {
    width: 100%;
    height: auto;
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.download-page_infos {
    width: 20%;
    font-size: 12px;
    color: #FFF;
    text-align: center;
}


.download-page_infos strong {
    display: inline-block;
}

.download-page_infos-texts {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}

.download-page_infos-texts span {
    margin-bottom: 16px;
}

.download-page_infos-texts strong {
    margin-bottom: 10px;
}

.download-page_infos-texts strong + strong {
    margin-bottom: 20px;
}

.app-presentation {
    color: #00FF79;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.app-presentation img {
    width: 19vw !important;
    height: 20vw !important;
    object-fit: cover;
}

.download-page_image-montage {
    width: 50vw;
    height: 80vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-row-gap: 10px;
    row-gap: 10px;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    margin-bottom: 20px;
}

.download-page_image-montage img {
    border-radius: 10px;
}

.download-page_image-montage #lake {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.download-page_image-montage #mountain {
    /* grid-area: mountain; */
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.download-page_image-montage #surf {
    /* grid-area: surf; */
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 4;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.download-page_image-montage #sentence {
    /* grid-area: sentence; */
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 6;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.download-page_image-montage #cycle {
    /* grid-area: cycle; */
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.download-page_content img {
    width: 50vw;
    height: 50vw;
}

.download-page_content strong.text_description_spotlight {
    color: #00FF79;
    padding-bottom: 40px;
}

.modal_container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
}

.app_pass_modal {
    width: 70%;
    height: auto;
    text-align: center;
    background-color: #222222;
    border-radius: 20px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.app_pass_modal_title {
    color: #00FF79;
}

.app_pass_modal_desc {
    color: #FFF;
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 14px;
}

.app_pass_modal button {
    margin-top: 30px;
    border: none;
    background-color: #00FF79;
    width: 120px;
    border-radius: 15px;
    font-weight: 800;
    font-family: 'Montserrat';
}

@media (max-width: 600px) {
    .download-page_content {
        flex-direction: column;
    }

    .download-page_content .download-page_infos {
        width: 50%;
    }

    .download-page_content .download-page_image-montage {
        width: 75vw;
    }
}
