@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700;800&display=swap');

button {
  font-family: 'Montserrat';
  cursor: pointer;
}

body {
  font-family: 'Montserrat';
  margin: 0;
  padding: 0;
  background: #141414;
}

h1 {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
}

.invisible-button {
  background-color: transparent;
  border-color: transparent;
}

.adventurous-logo {
  position: absolute;
  top: 0px;
  left: 0;
  max-width: 200px;
  max-height: 100px;
  width: 25%;
  height: auto;
  z-index: 200;
  border-bottom-right-radius: 20px;
  cursor: pointer;
}

.strong-green-text {
  color: #00FF79 !important;
}

.adventurous-button {
  width: auto;
  height: auto;
  font-size: 2vw;
  padding: 5px 10px;
  border-radius: 7px;
  border-color: transparent;
  background-color: #00FF79;
  color: #FFFFFF;
}

.navigation-header {
  position: absolute;
  right: 0px;
  padding-right: 20px;
  justify-content: flex-end;
  top: -16px;
  padding-bottom: 5px;
  background-color: rgba(0,0,0,0.5);
  border-bottom-left-radius: 20px;
  padding-inline-start: 15px;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 200;
}

.navigation-header li {
  font-size: 20px;
  margin-left: 1vw;
  cursor: pointer;
  border-left-width: 1;
  border-left-color: #000000;
}

.navigation-header li a {
  text-decoration: none;
  color: #000000;
}

.navigation-header.white li a {
  color: #FFFFFF;
}

.download-button {
  background-color: #00FF79;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 700;
  width: 160px;
  height: 40px;
  border-color: transparent;
  margin-bottom: 20px;
  margin-left: 50%;
  transform: translateX(-50%);
}

.no-style-button {
  border: none;
  background: none;
}

.download_call {
  margin-top: 5%;
  font-size: 1vw;
  color: #FFF;
}

@media (max-width: 600px) {
  .navigation-header {
    padding-right: 5px;
    padding-inline-start: 5px;
  }

  .navigation-header li {
    font-size: 12px !important;
    margin-left: 10px;
  }

  .title {
    font-size: 18px !important;
  }

  .subtitle {
    font-size: 12px !important;
  }

  .text {
    font-size: 10px !important;
  }

  .download-button {
    font-size: 15px !important;
    width: 100px !important;
    height: 30px !important;
  }
}